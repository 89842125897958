// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-checkout-checkout-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-checkout/templates/Checkout/Checkout.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-checkout-checkout-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-order-confirmation-order-confirmation-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-checkout/templates/OrderConfirmation/OrderConfirmation.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-checkout-templates-order-confirmation-order-confirmation-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-customer-blog-templates-default-blog-post-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-customer-blog/templates/DefaultBlogPost.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-customer-blog-templates-default-blog-post-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-press-jsx": () => import("./../../../src/pages/press.jsx" /* webpackChunkName: "component---src-pages-press-jsx" */),
  "component---src-pages-products-jsx": () => import("./../../../src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */)
}

