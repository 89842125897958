module.exports = [{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-customer-blog/gatsby-browser.js'),
      options: {"plugins":[],"layoutComponent":"/app/workspaces/v_1/website_envs/merchants_115/src/components/WebsiteLayout.jsx"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Vintage Boutique, LLC","short_name":"The Vintage Boutique, LLC","start_url":"/","background_color":"#ffc729","theme_color":"#ffc729","display":"standalone","icon":"./src/favicon.png","icons":[{"src":"./src/favicon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f5dd58fc5080ad87cf0e2e8933b120ff"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":10}},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":10}}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://thevintageboutiquestyle.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/@bluefin/gatsby-plugin-fisherman-checkout/gatsby-browser.js'),
      options: {"plugins":[],"businessType":"merchant","businessId":115,"layoutComponent":"/app/workspaces/v_1/website_envs/merchants_115/src/components/WebsiteLayout.jsx"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TNS8PDC","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    }]
